var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{[_vm.schema.type]: true}},[(_vm.schema.type === 'doctorSelect' && _vm.showDoctorSelect)?_c('DoctorListSelect',{on:{"close":function($event){_vm.showDoctorSelect = false},"select":_vm.handleSelectDoctor}}):_vm._e(),(_vm.schema.type === 'doctorSelectAll' && _vm.showDoctorSelect)?_c('DoctorListSelectAll',{on:{"close":function($event){_vm.showDoctorSelect = false},"select":_vm.handleSelectDoctor}}):_vm._e(),(_vm.schema.type === 'doctorSelectAllAndNew' && _vm.showDoctorSelect)?_c('DoctorListSelectAllAndNew',{on:{"close":function($event){_vm.showDoctorSelect = false},"select":_vm.handleSelectDoctor}}):_vm._e(),_c('div',{class:{valueBorder: _vm.valueBorder, field: true}},[(_vm.schema.type !== 'space')?_c('van-field',{attrs:{"type":_vm.schema.type,"value":[
          'select',
          'doctorSelect',
          'doctorSelectAll',
          'doctorSelectAllAndNew',
          'userSelect',
          'singleUserSelect',
          'number',
          'radio',
        ].includes(_vm.schema.type)
          ? _vm.schema.options?.find((item) => item.id === _vm.value)?.text ||
            _vm.innerOptions?.find((item) => item.id === _vm.value)?.text ||
            (_vm.value && String(_vm.value))
          : _vm.value && String(_vm.value),"placeholder":_vm.schema.placeholder,"formatter":['date'].includes(_vm.schema.type) ? _vm.dateFormater : undefined,"readonly":[
          'select',
          'date',
          'userSelect',
          'singleUserSelect',
          'doctorSelect',
          'doctorSelectAll',
          'doctorSelectAllAndNew',
          'number',
          'radio',
        ].includes(_vm.schema.type),"disabled":!_vm.editable,"clickable":['select', 'date', 'userSelect', 'singleUserSelect'].includes(
          _vm.schema.type,
        ),"label":_vm.schema.label,"required":_vm.schema.required},on:{"input":function($event){return _vm.$emit('input', $event)},"click":function($event){return _vm.handleFieldClick(_vm.schema)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [(_vm.$slots.default)?_vm._t("default",null,{"default":""}):(_vm.schema.type === 'checkbox')?_c('van-checkbox-group',{attrs:{"direction":"horizontal","disabled":!_vm.editable,"value":_vm.value},on:{"input":function($event){_vm.$emit('input', $event);
            _vm.schema.props?.onChange?.($event);}}},_vm._l((_vm.schema.options),function(item,index){return _c('van-checkbox',{key:index,attrs:{"name":item.value,"shape":"square"}},[_vm._v(_vm._s(item.text)+" ")])}),1):(_vm.schema.type === 'radio')?_c('van-radio-group',{staticClass:"radio-field",attrs:{"value":_vm.value,"direction":"horizontal"},on:{"input":function($event){_vm.$emit('input', $event);
            _vm.schema.props?.onChange?.($event);}}},_vm._l((_vm.schema.options),function(item,index){return _c('van-radio',{key:index,attrs:{"name":item.value}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1):(_vm.schema.type === 'multiSelect')?_c('MultiSelectField',_vm._b({attrs:{"value":_vm.formatFromValue(_vm.value),"options":_vm.schema.options},on:{"input":function($event){_vm.$emit('input', _vm.formatToValue($event))}}},'MultiSelectField',_vm.schema.props,false)):(_vm.schema.type === 'treeSelect')?_c('TreeSelectField',_vm._b({attrs:{"value":_vm.formatFromValue(_vm.value),"options":_vm.schema.options},on:{"input":function($event){_vm.$emit('input', _vm.formatToValue($event))}}},'TreeSelectField',_vm.schema.props,false)):(_vm.schema.type === 'userSelect')?_c('UserSelectField',_vm._b({attrs:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event)}}},'UserSelectField',_vm.schema.props,false)):(_vm.schema.type === 'singleUserSelect')?_c('UserSelectField',_vm._b({attrs:{"multiple":false,"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event)}}},'UserSelectField',_vm.schema.props,false)):(_vm.schema.type === 'number')?_c('van-field',_vm._b({staticClass:"number-field",attrs:{"value":_vm.value,"type":"number"},on:{"input":function($event){return _vm.$emit('input', $event)}}},'van-field',_vm.schema.props,false)):_vm._e()]},proxy:true},{key:"button",fn:function(){return [(['select'].includes(_vm.schema.type))?_c('van-icon',{attrs:{"name":"arrow-down"}}):_vm._e()]},proxy:true}],null,true)}):_c('div',{style:({height: _vm.schema.type.height || '16px'})}),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showPicker),callback:function ($$v) {_vm.showPicker=$$v},expression:"showPicker"}},[_c('van-picker',{attrs:{"show-toolbar":"","columns":_vm.schema?.options},on:{"confirm":function($event){_vm.showPicker = false;
          _vm.$emit('input', $event.id || $event);
          _vm.$emit('change', $event.id || $event);},"cancel":function($event){_vm.showPicker = false}}})],1),_c('van-calendar',{attrs:{"min-date":new Date(0),"color":"rgb(25, 137, 250)"},on:{"confirm":function($event){_vm.showCalendar = false;
        _vm.$emit('input', $event);}},model:{value:(_vm.showCalendar),callback:function ($$v) {_vm.showCalendar=$$v},expression:"showCalendar"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }