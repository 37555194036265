<template>
  <SwipeDialog
    :title="action + title"
    :context-menu-actions="contextMenuActions"
    :show-context-menu="showContextMenu"
    style="height: 100%; overflow: scroll"
    @closeFn="$router.go(-1)"
    @contextMenuFn="contextMenuFn"
  >
    <div style="padding: 10px">
      <div style="margin-bottom: 16px">
        <SchemaField
          v-for="(item, index) in fields"
          :key="index"
          v-model="formState[item.field]"
          :schema="item"
          @change="item?.onChange && item.onChange($event, formState)"
        />
      </div>
    </div>
    <BottomAction :confirm-loading="saveLoading" @save="save" />
  </SwipeDialog>
</template>

<script>
import BottomAction from './components/BottomAction.vue';
import SwipeDialog from '@/views/layout/swipeDialog';
import SchemaField from '@/components/form/SchemaField.vue';
import {
  createEditInsightRecord,
  fetchOnePhysicianMslInsights,
  addMedicalMeetings,
  editMedicalMeetings,
  deleteMedicalMeetings,
  fetchMedicalMeetingDetail,
  addMedicalTrainings,
  editMedicalTrainings,
  deleteMedicalTrainings,
  fetchMedicalTrainingDetail,
} from '@/api/kol';
import {validateFields} from './util';
import {Dialog, Toast} from 'vant';
import {getLocalStorage} from '@/utils';
import {
  fetchInsightTopics,
  deleteMslInsight,
  createEditPhysicianMslInsightQuestions,
  fetchPhysicianMslInsightQuestions,
} from '@/api/kol';
import asyncData from '@/mixins/asyncData';
import kvpKeyMixin from '@/mixins/kvpKey';
export default {
  components: {BottomAction, SwipeDialog, SchemaField},
  mixins: [
    kvpKeyMixin('product_or_disease'),
    kvpKeyMixin('ta'),
    kvpKeyMixin('medical_meeting_type'),
    kvpKeyMixin('medical_meeting_role'),
    kvpKeyMixin('medical_training_length'),
    kvpKeyMixin('medical_training_channel'),
  ],
  data() {
    return {
      physicianOptions: [],
      insightFormState: {},
      saveLoading: false,
      formState: {
        physician_id: '',
        insight_source: '',
        insight_time: new Date(),
        involve_scheduling: '',
        involve_speaker: '',
        involve_onsite_support: '',
        no_speaker_communication: null,
        product: '',
        product_id: '',
        training_subject: '',
        training_date: '',
        training_length: '',
        training_channel: '',
        trainee_source: '',
        no_trainee: null,
        description: '',
      },
      insightTopicList: null,
      questions: [],
      contextMenuActions: [{text: '删除'}],
      products: [],
    };
  },
  computed: {
    title() {
      return this.$route.query?.type === 'training' ? '培训' : '会议';
    },
    action() {
      return this.editId ? '编辑' : '新建';
    },
    editId() {
      return this.$route.query?.id;
    },
    resultFormState() {
      return {...this.formState, ...this.insightFormState};
    },
    showContextMenu() {
      return this.$route.query?.id?.length > 0;
    },
    fields() {
      if (this.$route.query?.type === 'training') {
        return [
          {
            field: 'training_subject',
            label: '培训主题',
            type: 'input',
            required: true,
          },
          {
            field: 'training_date',
            type: 'date',
            label: '培训日期',
            required: true,
          },
          {
            field: 'training_length',
            label: '培训时长',
            type: 'select',
            options: this.medical_training_length.map(
              (item) => item.kvp_key_name,
            ),
            required: true,
          },
          {
            field: 'training_channel',
            label: '培训形式',
            type: 'select',
            options: this.medical_training_channel.map(
              (item) => item.kvp_key_name,
            ),
            required: true,
          },
          {
            field: 'trainee_source',
            label: '学员来源',
            type: 'input',
            required: true,
          },
          {
            field: 'no_trainee',
            label: '学员人数',
            type: 'number',
            required: true,
          },
          {
            field: 'product',
            type: 'select',
            label: '产品或疾病',
            options: this.products.map((item) => item.kvp_key_name),
            onChange: (e, form) => {
              const target = this.products.find(
                (item) => item.kvp_key_name === e,
              );
              form.product_id = target.kvp_key_id;
            },
            required: true,
          },
          {
            field: 'description',
            label: '补充说明',
            type: 'textarea',
            required: false,
          },
        ];
      } else {
        return [
          {
            field: 'meeting_subject',
            label: '会议名称或主题',
            type: 'input',
            required: true,
          },
          {
            field: 'meeting_date',
            type: 'date',
            label: '会议日期',
            required: true,
          },
          {
            field: 'meeting_type',
            type: 'select',
            label: '会议类型',
            options: this.medical_meeting_type.map((item) => item.kvp_key_name),
            required: true,
          },
          {
            field: 'no_participant',
            label: '参会人数',
            type: 'number',
            required: true,
          },
          {
            field: 'role',
            label: '角色',
            type: 'select',
            options: this.medical_meeting_role.map((item) => item.kvp_key_name),
            onChange: (e, form) => {
              this.$forceUpdate(); // 强制更新视图
            },
            required: true,
          },
          /* eslint-disable */
          ...(this.formState.role === '支持线下推广性会议'
            ? [
                {
                  field: 'involve_scheduling',
                  label: '是否参与议程制定',
                  type: 'radio',
                  options: [
                    {value: 1, label: '是'},
                    {value: '0', label: '否'},
                  ],
                  required: true,
                },
                {
                  field: 'involve_speaker',
                  label: '是否参与讲者选择讨论',
                  type: 'radio',
                  options: [
                    {value: 1, label: '是'},
                    {value: '0', label: '否'},
                  ],
                  onChange: (e, form) => {
                    this.$forceUpdate(); // 强制更新视图
                  },
                  required: true,
                },
                ...(this.formState.involve_speaker === 1
                  ? [
                      {
                        field: 'no_speaker_communication',
                        label: '参与几位讲者沟通',
                        type: 'number',
                        required: true,
                      },
                    ]
                  : []),
                {
                  field: 'involve_onsite_support',
                  label: '是否做了现场支持',
                  type: 'radio',
                  options: [
                    {value: 1, label: '是'},
                    {value: '0', label: '否'},
                  ],
                  required: true,
                },
              ]
            : []),
          {
            field: 'product',
            type: 'select',
            label: '产品或疾病',
            onChange: (e, form) => {
              const target = this.products.find(
                (item) => item.kvp_key_name === e,
              );
              form.product_id = target.kvp_key_id;
              form.ta_id = target.parent_kvp_key_id;
              form.ta = this.ta.find(
                (item) => item.kvp_key_id === form.ta_id,
              )?.kvp_key_name;
              console.log('ta', form.ta_id, form.ta);
            },
            options: this.products.map((item) => item.kvp_key_name),
            required: true,
          },
          {
            field: 'description',
            label: '补充说明',
            type: 'textarea',
            required: false,
          },
        ];
      }
    },
  },
  watch: {
    product_or_disease(val) {
      const tas = getLocalStorage('tas');
      if (tas) {
        const taList = tas.split(',');
        this.products = val.filter(
          (item) => taList.indexOf(item.parent_kvp_key_id) >= 0,
        );
      }
      console.log('product_or_disease', val, this.products);
    },
  },
  mounted() {
    if (this.editId) {
      this.fetchOnePhysicianMslInsights();
    } else {
      this.fetchInsightTopics();
    }
  },
  methods: {
    async fetchInsightTopics() {
      const res = await fetchInsightTopics(
        this.insightFormState.insight_question?.insight_topic_id,
      );
      this.insightTopicList = res;
      this.optionsKIT = res.map((item) => ({
        text: item.topic_name,
        id: item.insight_topic_id,
      }));
    },
    async fetchOnePhysicianMslInsights() {
      let res;
      if (this.$route.query?.type === 'training') {
        res = await fetchMedicalTrainingDetail(this.editId);
      } else {
        res = await fetchMedicalMeetingDetail(this.editId);
      }
      this.formState = res;
      this.fetchInsightTopics();
    },
    save() {
      console.log('this.formState', this.formState);
      if (this.$route.query?.type === 'training') {
        this.saveTraining();
      } else {
        this.saveMeeting();
      }
    },
    async saveMeeting() {
      const state = this.formState;
      console.log(state);
      const payload = {
        description: state.description,
        meeting_date: state.meeting_date,
        meeting_subject: state.meeting_subject,
        meeting_type: state.meeting_type,
        no_participant: state.no_participant,
        product: state.product,
        product_id: state.product_id,
        role: state.role,
        ta: state.ta,
        ta_id: state.ta_id,
        user_id: getLocalStorage('user_id'),
        user_name: getLocalStorage('user_name'),
      };

      if (state.involve_onsite_support) {
        payload.involve_onsite_support = Number(state.involve_onsite_support);
      }
      if (state.involve_scheduling) {
        payload.involve_scheduling = Number(state.involve_scheduling);
      }
      if (state.involve_speaker) {
        payload.involve_speaker = Number(state.involve_speaker);
      }
      if (state.no_speaker_communication) {
        payload.no_speaker_communication = state.no_speaker_communication;
      }
      // if (!validateFields(this.fields, payload)) return;
      this.saveLoading = true;
      const res = await (this.editId
        ? editMedicalMeetings(this.editId, payload)
        : addMedicalMeetings(payload));
      this.saveLoading = false;
      if (res?.is_success) {
        Toast(this.editId ? '修改成功' : '创建成功');
        setTimeout(() => {
          this.$router.go(-1);
        }, 100);
      }
    },
    async saveTraining() {
      const state = this.formState;
      const payload = {
        description: state.description,
        no_trainee: state.no_trainee,
        product: state.product,
        product_id: state.product_id,
        ta: state.ta,
        ta_id: state.ta_id,
        trainee_source: state.trainee_source,
        training_channel: state.training_channel,
        training_date: state.training_date,
        training_length: state.training_length,
        training_subject: state.training_subject,
        training_source: state.training_source,
        user_id: getLocalStorage('user_id'),
        user_name: getLocalStorage('user_name'),
      };
      if (!validateFields(this.fields, payload)) return;
      this.saveLoading = true;
      const res = await (this.editId
        ? editMedicalTrainings(this.editId, payload)
        : addMedicalTrainings(payload));
      this.saveLoading = false;
      if (res?.is_success) {
        Toast(this.editId ? '修改成功' : '创建成功');
        setTimeout(() => {
          this.$router.go(-1);
        }, 100);
      }
    },
    contextMenuFn(action) {
      if (action === '删除') {
        Dialog.confirm({
          message: '是否删除这条记录',
          beforeClose: this.beforeClose,
        });
      }
    },
    beforeClose(action, done) {
      if (action === 'confirm') {
        this.saveLoading = true;
        if (this.editId) {
          console.log('editId---->', this.editId);
          if (this.$route.query.type === 'training') {
            deleteMedicalTrainings(this.editId)
              .then((res) => {
                console.log('cnm----->', res);
                if (res?.value === this.editId) {
                  this.$toast.success('删除成功');
                  setTimeout(() => {
                    this.$router.go(-1);
                  }, 100);
                } else {
                  this.$toast.fail('删除失败，请重试');
                }
                this.saveLoading = false;
                done();
              })
              .catch((err) => {
                console.log(err);
                this.$toast.fail('删除失败，请重试');
                this.saveLoading = false;
                done();
              });
          } else {
            deleteMedicalMeetings(this.editId)
              .then((res) => {
                console.log('cnm----->', res);
                if (res?.value === this.editId) {
                  this.$toast.success('删除成功');
                  setTimeout(() => {
                    this.$router.go(-1);
                  }, 100);
                } else {
                  this.$toast.fail('删除失败，请重试');
                }
                this.saveLoading = false;
                done();
              })
              .catch((err) => {
                console.log(err);
                this.$toast.fail('删除失败，请重试');
                this.saveLoading = false;
                done();
              });
          }
        } else {
          this.$toast.fail('删除失败，请重试');
          this.saveLoading = false;
          done();
        }
      } else if (action === 'cancel') {
        done();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
