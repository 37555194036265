var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full"},[_c('MultiSelectDisplay',{attrs:{"list":_vm.showList},on:{"click":function($event){_vm.selected = _vm.multiple
        ? _vm.value?.map((item) => item[_vm.compareKey]) || []
        : _vm.value;
      _vm.showPopup = true;},"close":function($event){_vm.multiple ? _vm.value.splice($event, 1) : (_vm.value = undefined)}}}),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showPopup),callback:function ($$v) {_vm.showPopup=$$v},expression:"showPopup"}},[_c('div',{staticClass:"container",staticStyle:{"max-height":"60vh","overflow":"auto"}},[_c('div',[_c('AsyncList',{attrs:{"fn":_vm.fetchVUserMsls,"page-size":20},scopedSlots:_vm._u([{key:"default",fn:function({list, refresh}){return [_c('div',{staticStyle:{"padding":"16px 0","font-size":"16px","position":"sticky","top":"0","z-index":"99","background-color":"white"}},[_c('div',{staticClass:"text-center font-bold flex justify-between"},[_c('div',{staticClass:"van-picker__cancel",on:{"click":function($event){_vm.showPopup = false}}},[_vm._v(" 取消 ")]),_c('div',{staticClass:"van-picker__confirm",on:{"click":function($event){_vm.showPopup = false;
                    _vm.$emit(
                      'input',
                      _vm.multiple
                        ? _vm.selected.map((item) =>
                            list.find(
                              (listItem) => listItem[_vm.compareKey] === item,
                            ),
                          )
                        : _vm.selected,
                    );}}},[_vm._v(" 确认 ")])]),_c('div',[_c('van-search',{attrs:{"clearable":false},on:{"search":refresh,"input":refresh},model:{value:(_vm.searchKey),callback:function ($$v) {_vm.searchKey=$$v},expression:"searchKey"}})],1)]),_c(_vm.multiple ? 'van-checkbox-group' : 'van-radio-group',{tag:"component",model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('van-cell-group',_vm._l((list),function(item,index){return _c('van-cell',{key:index,attrs:{"clickable":""},on:{"click":function($event){return _vm.toggle(item, index)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticStyle:{"font-size":"16px"}},[_vm._v(_vm._s(`${item.user_name}`))]),_c('span',{staticStyle:{"font-size":"12px","color":"#999","margin-left":"10px"}},[_vm._v(_vm._s(item.position))])]},proxy:true},{key:"right-icon",fn:function(){return [(_vm.multiple)?_c('van-checkbox',{ref:"checkboxes",refInFor:true,attrs:{"name":item[_vm.compareKey]}}):_c('van-radio',{attrs:{"name":item}})]},proxy:true}],null,true)})}),1)],1)]}}])})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }