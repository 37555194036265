<template>
  <div :class="{[schema.type]: true}">
    <DoctorListSelect
      v-if="schema.type === 'doctorSelect' && showDoctorSelect"
      @close="showDoctorSelect = false"
      @select="handleSelectDoctor"
    />
    <DoctorListSelectAll
      v-if="schema.type === 'doctorSelectAll' && showDoctorSelect"
      @close="showDoctorSelect = false"
      @select="handleSelectDoctor"
    />
    <DoctorListSelectAllAndNew
      v-if="schema.type === 'doctorSelectAllAndNew' && showDoctorSelect"
      @close="showDoctorSelect = false"
      @select="handleSelectDoctor"
    />
    <div :class="{valueBorder, field: true}">
      <van-field
        v-if="schema.type !== 'space'"
        :type="schema.type"
        :value="
          [
            'select',
            'doctorSelect',
            'doctorSelectAll',
            'doctorSelectAllAndNew',
            'userSelect',
            'singleUserSelect',
            'number',
            'radio',
          ].includes(schema.type)
            ? schema.options?.find((item) => item.id === value)?.text ||
              innerOptions?.find((item) => item.id === value)?.text ||
              (value && String(value))
            : value && String(value)
        "
        :placeholder="schema.placeholder"
        :formatter="['date'].includes(schema.type) ? dateFormater : undefined"
        :readonly="
          [
            'select',
            'date',
            'userSelect',
            'singleUserSelect',
            'doctorSelect',
            'doctorSelectAll',
            'doctorSelectAllAndNew',
            'number',
            'radio',
          ].includes(schema.type)
        "
        :disabled="!editable"
        :clickable="
          ['select', 'date', 'userSelect', 'singleUserSelect'].includes(
            schema.type,
          )
        "
        :label="schema.label"
        :required="schema.required"
        @input="$emit('input', $event)"
        @click="handleFieldClick(schema)"
      >
        <template #input>
          <slot v-if="$slots.default" default></slot>
          <van-checkbox-group
            v-else-if="schema.type === 'checkbox'"
            direction="horizontal"
            :disabled="!editable"
            :value="value"
            @input="
              $emit('input', $event);
              schema.props?.onChange?.($event);
            "
          >
            <van-checkbox
              v-for="(item, index) in schema.options"
              :key="index"
              :name="item.value"
              shape="square"
              >{{ item.text }}
            </van-checkbox>
          </van-checkbox-group>
          <van-radio-group
            v-else-if="schema.type === 'radio'"
            class="radio-field"
            @input="
              $emit('input', $event);
              schema.props?.onChange?.($event);
            "
            :value="value"
            direction="horizontal"
          >
            <van-radio
              :name="item.value"
              v-for="(item, index) in schema.options"
              :key="index"
            >
              {{ item.label }}
            </van-radio>
          </van-radio-group>
          <MultiSelectField
            v-else-if="schema.type === 'multiSelect'"
            :value="formatFromValue(value)"
            :options="schema.options"
            v-bind="schema.props"
            @input="$emit('input', formatToValue($event))"
          />
          <TreeSelectField
            v-else-if="schema.type === 'treeSelect'"
            :value="formatFromValue(value)"
            :options="schema.options"
            v-bind="schema.props"
            @input="$emit('input', formatToValue($event))"
          />
          <!-- 用户选择 -->
          <UserSelectField
            v-else-if="schema.type === 'userSelect'"
            :value="value"
            v-bind="schema.props"
            @input="$emit('input', $event)"
          />
          <!-- 用户选择 -->
          <UserSelectField
            v-else-if="schema.type === 'singleUserSelect'"
            :multiple="false"
            :value="value"
            v-bind="schema.props"
            @input="$emit('input', $event)"
          />
          <!-- 数字输入 -->
          <van-field
            v-else-if="schema.type === 'number'"
            :value="value"
            v-bind="schema.props"
            type="number"
            class="number-field"
            @input="$emit('input', $event)"
          />
        </template>
        <template #button>
          <van-icon v-if="['select'].includes(schema.type)" name="arrow-down" />
          <!-- <van-icon
              v-else-if="['date'].includes(schema.type)"
              name="clear"
              @click="handleClear(schema, $event)"
            /> -->
        </template>
      </van-field>
      <div v-else :style="{height: schema.type.height || '16px'}"></div>
      <van-popup v-model="showPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="schema?.options"
          @confirm="
            showPicker = false;
            $emit('input', $event.id || $event);
            $emit('change', $event.id || $event);
          "
          @cancel="showPicker = false"
        />
      </van-popup>
      <van-calendar
        v-model="showCalendar"
        :min-date="new Date(0)"
        color="rgb(25, 137, 250)"
        @confirm="
          showCalendar = false;
          $emit('input', $event);
        "
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import {Field} from 'vant';
import {Tab, Tabs} from 'vant';
import {Picker} from 'vant';
import {Popup} from 'vant';
import {Icon} from 'vant';
import {Calendar} from 'vant';
import * as moment from 'moment';
import MultiSelectField from './MultiSelectField.vue';
import TreeSelectField from './TreeSelectField.vue';
import {setLocalStorage, getLocalStorage} from '@/utils/index';
import {eventBus} from '@/utils/eventBus';
import DoctorListSelect from '@/components/DoctorListSelect.vue';
import DoctorListSelectAll from '@/components/DoctorListSelectAll.vue';
import DoctorListSelectAllAndNew from '@/components/DoctorListSelectAllAndNew.vue';
import {fetchOnePhysician} from '@/api/kol';
import UserSelectField from '@/components/form/UserSelectField.vue';
import MultiSelectDisplay from '@/components/form/MultiSelectDisplay.vue';

Vue.use(Calendar);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Field);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Icon);

export default {
  components: {
    // MultiSelectDisplay,
    TreeSelectField,
    UserSelectField,
    MultiSelectField,
    DoctorListSelect,
    DoctorListSelectAll,
    DoctorListSelectAllAndNew,
  },
  props: {
    valueBorder: {
      type: Boolean,
      default() {
        return true;
      },
    },
    schema: {
      type: Object,
      default: () => ({}),
    },
    editable: {
      type: Boolean,
      default: true,
    },
    value: null,
  },
  data() {
    return {
      showPicker: false,
      showCalendar: false,
      showDoctorSelect: false,
      innerOptions: [],
      showUserSelect: false,
    };
  },
  watch: {
    value(val) {
      if (
        this.schema.type === 'doctorSelect' ||
        this.schema.type === 'doctorSelectAll' ||
        this.schema.type === 'doctorSelectAllAndNew'
      ) {
        const id = val;

        // 选项数据不存在则请求获取数据，以展示医生名称
        if (
          ![...(this.schema?.options ?? []), ...(this.innerOptions ?? [])].find(
            (item) => item.id === id,
          )
        ) {
          fetchOnePhysician(id).then((data) => {
            this.innerOptions = [data]?.map?.((item) => ({
              text: item.physician_name,
              id: item.physician_id,
            }));
          });
        }
      }
    },
  },
  methods: {
    dateFormater(value) {
      if (!value) return '';
      return moment(value).format('YYYY-MM-DD');
    },
    handleFieldClick(item) {
      if (item.type === 'select') {
        this.showPicker = true;
      } else if (item.type === 'date') {
        this.showCalendar = true;
      } else if (
        item.type === 'doctorSelect' ||
        item.type === 'doctorSelectAll' ||
        item.type === 'doctorSelectAllAndNew'
      ) {
        this.showDoctorSelect = true;
      }
    },
    handleSelectDoctor(data) {
      const id = data[0]?.physician_id;
      this.$emit('input', id);
      this.$emit('change', id);
      this.$emit('get', data);
      this.schema.onChange?.(id);
      this.schema.onGet?.(data);
      this.innerOptions = data?.map?.((item) => ({
        text: item.physician_name,
        id: item.physician_id,
      }));
      this.showDoctorSelect = false;
    },
    handleUserSelect(data) {
      console.log(data, 'select');
      this.showUserSelect = false;
      this.$emit('input', data);
      this.$emit('change', data);
      this.schema.onChange?.(data);
    },
    formatToValue(value) {
      const formatFn = this.schema.formatToValue;
      if (formatFn) {
        return formatFn(value);
      }
      return value;
    },
    formatFromValue(value) {
      const formatFn = this.schema.formatFromValue;
      if (formatFn) {
        return formatFn(value);
      }
      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
.hideValue ::v-deep .van-field__value {
  display: none;
}
.field ::v-deep .van-cell {
  display: block;
}

.valueBorder ::v-deep .van-cell .van-cell__value {
  border: 1px solid #dcdcdc;
  margin: 6px 0px 0px;
  padding: 4px 8px;
  border-radius: 4px;
}

::v-deep .van-checkbox {
  // width: calc(50% - 20px);
  margin-bottom: 10px;
}

.checkbox ::v-deep .van-cell .van-cell__value {
  border: none;
  margin: 0 -8px -18px;
}

.radio ::v-deep .van-cell .van-cell__value {
  border: none;
}

::v-deep .van-checkbox-group {
  width: 100%;
}
.number-field {
  padding: 0 !important;
}
.number-field ::v-deep .van-cell__value {
  border: none !important;
  padding: 0 !important;
  margin-top: 0 !important;
}
::v-deep .van-field__label {
  width: auto;
}
</style>
