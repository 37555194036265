<template>
  <SwipeDialog title="医生选择" @closeFn="$emit('close')">
    <DoctorList
      style="padding-bottom: 44px"
      :on-select-item="handleSelectItem"
      :selected-items="selectedIdList"
    />
    <van-button
      type="info"
      block
      style="position: fixed; bottom: 0"
      @click="handleConfirm"
    >确定</van-button>
  </SwipeDialog>
</template>

<script>
import DoctorList from '@/views/kol/list.vue';
import SwipeDialog from '@/views/layout/swipeDialog';
import {Button} from 'vant';
import Vue from 'vue';
import {eventBus} from '@/utils/eventBus';
import AsyncList from '@/components/AsyncList.vue';

Vue.use(Button);

export default {
  components: {
    DoctorList,
    SwipeDialog,
  },
  props: {
    multipleMode: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      selectedList: [],
      tabIndex: 0,
    };
  },
  computed: {
    selectedIdList() {
      return this.selectedList.map((item) => item.physician_id);
    },
  },
  methods: {
    handleConfirm() {
      this.$emit('select', this.selectedList);
    },
    handleSelectItem(item) {
      const id = item.physician_id;

      if (this.multipleMode) {
        const selectedIndex = this.selectedList.findIndex(
          (item) => item.physician_id === id,
        );

        if (selectedIndex > -1) {
          this.selectedList.splice(selectedIndex, 1);
        } else {
          this.selectedList.push(item);
        }
      } else {
        this.selectedList = [item];
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
